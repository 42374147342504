// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import "../src/Assets/css/styles.css";
// import { BrowserRouter } from "react-router-dom";

// const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );


// import React from "react";
// import "./index.css";
// import App from "./App";
// import "../src/Assets/css/styles.css";
// import { BrowserRouter } from "react-router-dom";
// import { hydrate, render } from "react-dom";

// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//     rootElement
//   );
// } else {
//   render(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//     rootElement
//   );
// }


import React from "react";
import "./index.css";
import App from "./App";
import "../src/Assets/css/styles.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

const rootElement = document.getElementById("root");

// Create a root with createRoot
const root = createRoot(rootElement);

// Check if the root element already has child nodes
const isRootHydrated = rootElement.hasChildNodes();

// Render or hydrate based on whether the root is already hydrated
if (isRootHydrated) {
  root.hydrate(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
} else {
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

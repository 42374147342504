import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import MainRoute from "../src/Components/Routes/MainRoute";

function App() {
  return (
    <>
      <MainRoute />
    </>
  );
}

export default App;
